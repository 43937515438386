<template>
    <b-row>

            <b-col>
                <ValidationObserver ref="formModalValidate">
                <b-col>
                    <ValidationProvider name="tr"
                                        rules="required"
                                        v-slot="{ valid, errors }">
                    <b-form-group :label="$t('tr_name')">
                        <b-form-input type="text"
                                      v-model="formData.text.tr"
                                      :placeholder="$t('tr_name')"/>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col>
                    <ValidationProvider name="en"
                                        rules="required"
                                        v-slot="{ valid, errors }">
                    <b-form-group :label="$t('en_name')">
                        <b-form-input type="text"
                                      v-model="formData.text.en"
                                      :placeholder="$t('en_name')"/>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                </ValidationObserver>
            </b-col>

            <div class="col-12 mt-3 d-flex">
                <b-button @click="update" type="button" variant="primary" class="btn-lg mr-2">
                    {{ $t("save") | toUpperCase }}
                </b-button>
            </div>


    </b-row>

</template>
<script>
import TranslationService from "../../../services/TranslationService";

export default {
    props: {
        formId: {}
    },
    data() {
        return {
            formData: {
                group:null,
                key:null,
                text: {
                    tr: null,
                    en: null,
                }
            }

        }
    },
    created() {
        this.getValue(this.formId)
    },
    methods: {
        getValue(id) {
            TranslationService.get(id)
                .then(res => {
                    let data = res.data.data
                    this.formData={
                        text : {
                            tr: data.text.tr,
                            en: data.text.en
                        },
                        key:data.key,
                        group:data.group
                    }

                }).catch(err => {
                this.showErrors(err)
            })
        },
     async update() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (!isValid){
                return
            }
         let formData = {
             ...this.formData
         }
            TranslationService.put(this.formId,formData)
                .then(resp => {
                    this.$toast.success(resp.data.message)
                   this.$emit('updated',true)


                }).catch(err => {
                this.showErrors(err)
            })

        }

    }
}

</script>
<style>

</style>


