import Api from '@/services/Index';

const getAll = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/translations/', data);
}
const get = async (formId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/translations/'+formId);
}
const put = async (formId,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/translations/'+formId,formData);
}
const del = async (formId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/translations/'+formId);
}
const excelExport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/translations/excel-export', {...config, responseType: 'arraybuffer'})
}




export default {
    getAll,
    get,
    put,
    del,
    excelExport
}
